
//////////////////
//Farg`ona davlat universiteti ta\'lim sifatini nazorat qilish bo\'limi
export const environment = {
  //Eslatma TSNQB so‘zi o‘rniga KPI
  otm: 'fdu',
  main_name: 'KPI',
  production: true,
  http_auth: 'fghdUEfdko3FJIJsdfPK',
  otm_name:
    "Farg`ona davlat universiteti ta'lim sifatini nazorat qilish bo'limi",
  firebaseConfig: {
    // fdu
    apiKey: 'AIzaSyCtFGfn_M96D3hzQlxxUmMGTKHcNsp6oR4',
    authDomain: 'fdu1-138e9.firebaseapp.com',
    projectId: 'fdu1-138e9',
    storageBucket: 'fdu1-138e9.appspot.com',
    messagingSenderId: '86663595785',
    appId: '1:86663595785:web:fd512e84a8ce8cf86375d8',
    measurementId: 'G-B6GKYS7M9J',
  },
  http: {
    socket: {
      path: 'wss://kpi.fdu.uz/socket.io',
    },
    editArea: {
      path: 'https://kpi.fdu.uz/api/system/area',
    },
    addArea: {
      path: 'https://kpi.fdu.uz/api/system/area',
    },
    deleteArea: {
      path: 'https://kpi.fdu.uz/api/system/area',
    },
    removeDepartment: {
      path: 'https://kpi.fdu.uz/api/system/department',
    },
    editDepartment: {
      path: 'https://kpi.fdu.uz/api/system/department',
    },
    getDepartmentAArea: {
      path: 'https://kpi.fdu.uz/api/system/getAll',
    },
    addDepartment: {
      path: 'https://kpi.fdu.uz/api/system/department',
    },
    userRegister: {
      path: 'https://kpi.fdu.uz/api/auth/registration',
    },
    recoverPass: {
      path: 'https://kpi.fdu.uz/api/mailing/send-mail',
    },
    allRoles: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    removeRole: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    setRole: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    removeTeacher: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    allUsers: {
      path: 'https://kpi.fdu.uz/api/users/all',
    },
    updateUser: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    deleteUser: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    login: {
      path: 'https://kpi.fdu.uz/api/auth/login',
    },
    autologin: {
      path: 'https://kpi.fdu.uz/api/auth/autologin',
    },
    get: {
      // https://tsnqb.ferpi.uz/db_pvorey_test //https://2shahar.uz/tsnqb/get_data.php
      path: 'https://kpi.fdu.uz/db/2023/get_data.php',
      file: 'pvorey.txt',
    },
    delete_only_inform_file: {
      path: 'https://kpi.fdu.uz/db/2023/delete_only_inform_file.php',
    },
    getFieldsInform: {
      path: 'https://kpi.fdu.uz/db/2023/get_fields_inform.php',
    },
    setInc: {
      path: 'https://kpi.fdu.uz/db/2023/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://kpi.fdu.uz/db/2023/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://kpi.fdu.uz/db/2023/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://kpi.fdu.uz/db/2023/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://kpi.fdu.uz/db/2023/google_scholar.php',
    },
    googleScholarCitedAll: {
      path: 'https://kpi.fdu.uz/db_ferpi/2023/google_scholar_all.php',
    },
    setNewPvo: {
      path: 'https://kpi.fdu.uz/db/2023/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://kpi.fdu.uz/db/2023/remove_pvo.php',
    },
    controlIndexStatus: {
      path: 'https://kpi.fdu.uz/db/2023/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://kpi.fdu.uz/db/2023/create_announce.php',
    },
    getAnnounces: {
      path: 'https://kpi.fdu.uz/db/2023/get_announces.php',
    },
    removeAnnounce: {
      path: 'https://kpi.fdu.uz/db/2023/remove_announce.php',
    },
    editUserAccaunt: {
      path: 'https://kpi.fdu.uz/db/2023/edit_user_account.php',
    },
    createIndex: {
      path: 'https://kpi.fdu.uz/db/2023/create_index.php',
    },
  },
  tizim: [
    {
      fakultet: 'Fizika-texnika',
      kafedralar: ['Fizika', 'Texnologik taʼlim'],
    },
    {
      fakultet: 'Matematika-informatika',
      kafedralar: [
        'Matematika',
        'Matematik analiz va differentsial tenglamalar',
        'Аmaliy matematika va informatika',
        'Аxborot texnologiyalari',
      ],
    },
    {
      fakultet: 'Tabiiy fanlar',
      kafedralar: [
        'Kimyo',
        'Geografiya',
        'Ekologiya',
        'Zoologiya va umumiy biologiya',
        'Botanika va biotexnologiya',
      ],
    },
    {
      fakultet: 'Agrar qo‘shma',
      kafedralar: [
        'Аholi tomorqalaridan samarali foydalanish va dorivor o‘simliklar',
        'Tuproqshunoslik',
        'Mevachilik va sabzavotchilik',
      ],
    },
    {
      fakultet: 'Chet tillari',
      kafedralar: [
        'Ingliz tili',
        'Nemis va frantsuz tillari',
        'Gumanitar yoʼnalishlar boʼyicha chet tillari',
        'Tabiiy yoʼnalishlar boʼyicha chet tillari',
      ],
    },
    {
      fakultet: 'Ingliz tili va adabiyoti',
      kafedralar: ['Аmaliy ingliz tili', 'Ingliz tili oʼqitish metodikasi'],
    },
    {
      fakultet: 'Filologiya',
      kafedralar: [
        'Tilshunoslik',
        'Аdabiyotshunoslik',
        'Oʼzbek tili va adabiyoti',
        'Rus filologiyasi',
        'Rus tili metodikasi',
      ],
    },
    {
      fakultet: 'Tarix',
      kafedralar: [
        'Jahon tarixi',
        'Oʼzbekiston tarixi',
        'Sotsiologiya',
        'Falsafa',
        'Ijtimoiy ish',
        'Huquq ta’limi',
      ],
    },
    {
      fakultet: 'Pedagogika-psixologiya',
      kafedralar: ['Pedagogika', 'Psixologiya'],
    },
    {
      fakultet: 'Sanʼatshunoslik',
      kafedralar: [
        'Tasviriy sanʼat',
        'Musiqiy taʼlim va madaniyat',
        'Vokal va cholgʼu ijrochiligi',
      ],
    },
    {
      fakultet: 'Maktabgacha va boshlangʼich taʼlim',
      kafedralar: ['Boshlangʼich taʼlim uslubiyoti', 'Maktabgacha taʼlim'],
    },
    {
      fakultet: 'Jismoniy madaniyat',
      kafedralar: [
        'Jismoniy madaniyat',
        'Jismoniy madaniyat nazariyasi va uslubiyoti',
        'Sport oʼyinlari',
      ],
    },
    {
      fakultet: 'Iqtisodiyot',
      kafedralar: [
        'Jahon va mintaqa iqtisodiyoti',
        'Iqtisodiyot va servis',
        'Moliya',
        'Buxgalteriya hisobi va iqtisodiy taxlil',
      ],
    },
    {
      fakultet: 'Harbiy taʼlim',
      kafedralar: ['Harbiy taʼlim'],
    },
    {
      fakultet: 'Sirtqi boʼlim',
      kafedralar: ['Аniq va tabiiy fanlar', 'Ijtimoiy gumanitar fanlar'],
    },
  ],
  grastud: 556,
  fakultet: [
    'Fizika-texnika',
    'Matematika-informatika',
    'Tabiiy fanlar',
    'Agrar qo‘shma',
    'Chet tillari',
    'Ingliz tili va adabiyoti',
    'Filologiya',
    'Tarix',
    'Pedagogika-psixologiya',
    'Sanʼatshunoslik',
    'Maktabgacha va boshlangʼich taʼlim',
    'Jismoniy madaniyat',
    'Iqtisodiyot',
    'Harbiy taʼlim',
    'Sirtqi boʼlim',
  ],
  kafedra: [
    'Fizika',
    'Texnologik taʼlim',
    'Matematika',
    'Matematik analiz va differentsial tenglamalar',
    'Аmaliy matematika va informatika',
    'Аxborot texnologiyalari',
    'Kimyo',
    'Geografiya',
    'Ekologiya',
    'Zoologiya va umumiy biologiya',
    'Botanika va biotexnologiya',
    'Аholi tomorqalaridan samarali foydalanish va dorivor o‘simliklar',
    'Tuproqshunoslik',
    'Mevachilik va sabzavotchilik',
    'Ingliz tili',
    'Nemis va frantsuz tillari',
    'Gumanitar yoʼnalishlar boʼyicha chet tillari',
    'Tabiiy yoʼnalishlar boʼyicha chet tillari',
    'Аmaliy ingliz tili',
    'Ingliz tili oʼqitish metodikasi',
    'Tilshunoslik',
    'Аdabiyotshunoslik',
    'Oʼzbek tili va adabiyoti',
    'Rus filologiyasi',
    'Rus tili metodikasi',
    'Jahon tarixi',
    'Oʼzbekiston tarixi',
    'Sotsiologiya',
    'Falsafa',
    'Ijtimoiy ish',
    'Huquq ta’limi',
    'Pedagogika',
    'Psixologiya',
    'Tasviriy sanʼat',
    'Musiqiy taʼlim va madaniyat',
    'Vokal va cholgʼu ijrochiligi',
    'Boshlangʼich taʼlim uslubiyoti',
    'Maktabgacha taʼlim',
    'Jismoniy madaniyat',
    'Jismoniy madaniyat nazariyasi va uslubiyoti',
    'Sport oʼyinlari',
    'Jahon va mintaqa iqtisodiyoti',
    'Iqtisodiyot va servis',
    'Moliya',
    'Buxgalteriya hisobi va iqtisodiy taxlil',
    'Harbiy taʼlim',
    'Аniq va tabiiy fanlar',
    'Ijtimoiy gumanitar fanlar',
  ],
};
//////////////////
